import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  initialize () {
    const siteKey = `6LckG_AUAAAAAEi3QUA6OJ6p4M7JMNkXQOi-RZLe`;
    if (!window.grecaptcha) {
      const reCaptchaScript = document.createElement('script')
      window.reCaptchaOnload = () => {
        window.grecaptcha.render(this.containerTarget, {
          sitekey: siteKey,
          theme: 'light',
          size: 'normal'
        })
      }
      reCaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaOnload&render=explicit'
      reCaptchaScript.className = 'recaptcha-script'
      document.head.appendChild(reCaptchaScript)
    } else {
      window.grecaptcha.render(this.containerTarget, {
        sitekey: siteKey,
        theme: 'light',
        size: 'normal'
      })
    }
  }

  removeScript() {
    const reCaptchaScript = document.head.querySelector('.recaptcha-script')
    if (!!reCaptchaScript) {
      document.head.removeChild(reCaptchaScript)
    }
  }

  teardown() {
    this.removeScript()
    if (this.containerTarget) {
      this.containerTarget.innerHTML = ''
    }
  }

  disconnect() {
    this.teardown()
  }
}
